import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "measurementHelp", 
    'blueprintName', 
    'blueprintNameInput', 
    'blueprintNameField', 
    'blueprintNameEditBtn'
  ]
  // static values = {
  //   presets: Array
  // }

  connect() {
  }

  showHelp() {
    let htmlHelpContent = document.getElementById(`${event.target.dataset.sizeKey}-help`).innerHTML;
    this.measurementHelpTarget.innerHTML = htmlHelpContent;
  }

  editName() {
    event.preventDefault();
    this.blueprintNameTarget.classList.add('displaynone');
    this.blueprintNameFieldTarget.classList.remove('displaynone');
    this.blueprintNameEditBtnTarget.classList.add('displaynone');
    this.blueprintNameInputTarget.focus();
    this.blueprintNameInputTarget.select();
  }
}