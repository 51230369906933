// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "specialInstructions", "specialInstructionsLink" ]

  connect() {
  }

  toggleSpecialInstructions(event) {
    event.preventDefault()
    this.specialInstructionsTarget.classList.toggle('displaynone')
    this.specialInstructionsLinkTarget.classList.toggle('displaynone')
  }

  deleteLineItem(event) {
    event.preventDefault();
    let element = $(event.target);
    element.closest('.line-item').find('input.line_item_quantity').val(0);
    element.closest('form').submit();
    return false;
  }
}
