/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
require("@rails/ujs").start();
require('jquery')
require('popper.js')
require("bootstrap")
window.jQuery = $;
window.$ = $;

import 'lightgallery/css/lightgallery.css'


$(document).ready(function() {
  $('.panel-collapse').on('hide.bs.collapse', function() {
    $(this).parent().find('.indicator').addClass('glyphicon-chevron-left');
    return $(this).parent().find('.indicator').removeClass('glyphicon-chevron-down');
  });
  
  $('.panel-collapse').on('show.bs.collapse', function() {
    $(this).parent().find('.indicator').removeClass('glyphicon-chevron-left');
    return $(this).parent().find('.indicator').addClass('glyphicon-chevron-down');
  });

  var hide, offcanvas, show;
  offcanvas = {};

  hide = offcanvas.hide = function() {
    var $body, $elements, classes;
    $body = $('body');
    classes = $body[0].className.split(' ').filter(function(c) {
      return c.lastIndexOf('offcanvas-') !== 0 && c.lastIndexOf('show-offcanvas-') !== 0;
    });
    $body[0].className = $.trim(classes.join(' '));
    $elements = $('.offcanvas-left.active,.offcanvas-right.active');
    $elements.removeClass('active');
    $elements.addClass('hidden');
    if (window.lastFocus) {
      return window.lastFocus.focus();
    }
  };

  show = offcanvas.show = function(selector, style) {
    var $body, $selector, side;
    if (style == null) {
      style = 'push';
    }
    hide();
    $selector = $(selector);
    $selector.removeClass('hidden');
    $body = $('body');
    side = $selector.hasClass('offcanvas-left') ? 'left' : 'right';
    $body.addClass("show-offcanvas-" + side + " offcanvas-" + style);
    $selector.addClass('active');
    window.lastFocus = $(':focus');
    return setTimeout((function() {
      $selector.find('button.close').focus();
    }), 200);
  };

  $(document).on('click', '.glyphicon-star', function() {
    const $element = $("#" + $(this).parent().attr("for"));
    $element.prop("checked", true);
    return $element.change();
  });
  $(document).on('input', 'textarea', function() {
    this.style.height = '';
    return this.style.height = this.scrollHeight + 3 +'px';
  });
  $('textarea').each(function() {
    this.style.height = '';
    return this.style.height = (this.scroll.height != null) ? (this.scroll.height + 3 +'px') : (75 +'px');
  });
  $(document).on('click', '.toggle-form', function(event) {
    event.preventDefault();
    $(this).parent().children().toggle();
    return $(this).parent().find("textarea").each(function() {      
      this.style.height = '';
      return this.style.height = this.scrollHeight + 3 +'px';
    });
  });

  $('body').on("click", ".offcanvas-overlay,[data-dismiss='offcanvas']", function() {
    return hide();
  });

  window.offcanvas = offcanvas;
})

  