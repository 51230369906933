import { Controller } from "stimulus"

export default class extends Controller {


  connect() {
    console.log('connected')
  }

  toggleShippingAddress(event) {
    let useBilling = document.getElementById('order_use_billing')?.checked;
    document.getElementById('shipping-inner').style.display = useBilling ? 'none' : 'block';
  }

  togglePaymentMethod(event) {
    if ($('input[name="order[payments_attributes][][payment_method_id]"]:checked')) {
      $('input[name="use_existing_card"]').val('no');
      $('input[name="order[existing_card]"]').prop('checked', false);
      $('#hidden-payment-method').attr('name', 'ignore');
      $('.payment-method').addClass('test');
      document.querySelectorAll('.payment-method').forEach((element) => {
        element.style.display = 'none';
      })
      document.getElementById(`payment_method_${event.target.value}`).style.display = 'block';
    } else {
      $('input[name="use_existing_card"]').val('yes');
      document.getElementById(`payment_method_${event.target.value}`).style.display = 'none';
    }
  }

  selectExistingCard(event) {
    if ($('input[name="order[existing_card]"]:checked')) {
      $('input[name="use_existing_card"]').val('yes');
      $('input[name="order[payments_attributes][][payment_method_id]"]:checked').prop('checked', false);
      document.querySelectorAll('.payment-method').forEach((element) => {
        element.style.display = 'none';
      })
    } else {
      $('input[name="use_existing_card"]').val('no');
    }
  }
}
