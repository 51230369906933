import { Controller } from "stimulus"
export default class extends Controller {
  static values = {
    presets: Array
  }

  connect() {
    this.modifyHandler = this.modifyDependentOptions.bind(this)
    this.toggleHandler = this.toggleExpandIndictor.bind(this)
    this.helpHandler = this.showHelp.bind(this)
    
    document.querySelectorAll("input[data-modify]").forEach((element) => {
      element.addEventListener('change', this.modifyHandler)
    })
    document.querySelectorAll(".show-hide-toggle").forEach((element) => {
      element.addEventListener('click', this.toggleExpandIndictor)
    })
    document.querySelectorAll("a[data-help-key]").forEach((element) => {
      element.addEventListener('click', this.helpHandler)
    })
    
    document.querySelectorAll("input[type='radio']:checked").forEach((element) => {
      this.setSelected(element)
    })
    this.setPrice()
    document.getElementById('customization_loading').remove();
    document.getElementById('customization').style.display = 'block';
    setTimeout(this.showSignup, 5000)
  }

  disconnect() {
    document.querySelectorAll("input[data-modify]").forEach((element) => {
      element.removeEventListener('change', this.modifyHandler)
    })
    document.querySelectorAll(".show-hide-toggle").forEach((element) => {
      element.removeEventListener('click', this.toggleExpandIndictor)
    })
    document.querySelectorAll("a[data-help-key]").forEach((element) => {
      element.removeEventListener('click', this.helpHandler)
    })
  }

  showSignup() {
    $("#signup-prompt").fadeIn(200)
  }

  selectOption(event) {
    let radioButton = event.currentTarget.querySelector('input[type=radio]')
    this.setSelected(radioButton)
    // var change = new Event('change');
    // radioButton.dispatchEvent(change)
    $("#style_preset_id").val('')
  }

  setSelected(element, title = false) {
    let section = element.dataset["section"]
    let name = element.getAttribute("name")
    let selected_class = element.dataset["selectedClass"]
    title = title || element.dataset["title"] || element.value

    if (!element.checked) {
      element.checked = true // because clicking caption doesn't set checked
      var change = new Event('change');
      element.dispatchEvent(change)
    }

    $("[name='#{name}']").parent().removeClass('active')
    document.querySelectorAll(`[name='${name}']`).forEach((element) => {
      element.dataset['valid'] = true 
      element.parentElement.classList.remove('active')
    })

    element.parentElement.classList.add('active')
    // $('#invalid-form').hide() unless invalidFields().length // TODO
    $(`#${section}-panel`).removeClass("panel-danger")
    $(`#${section}-title .title`).html(title)
    $(`#${section}-title .title`).addClass("selected-value")
    $(`#${section}-title #${section}-icon`).removeClass().addClass("selected-icon " + selected_class)
    $(`#${section}-title`).find(".text-primary").addClass("glyphicon-ok")
    $(`#${section}-title`).find(".indicator").hide()
    this.setPrice();
  }

  setPrice() {
    let price = Number(document.getElementById('itemPrice')?.value)
    let elementsWithPrice = document.querySelectorAll("input[data-price-modifier]:checked")
    elementsWithPrice.forEach((element) => {
      if (element.dataset["priceModifier"]) {
        price += Number(element.dataset["priceModifier"])
      }
    })
    document.querySelectorAll('.variantprice').forEach((element) => {
      element.innerHTML = "$" + price.toString();
    })
  }

  validate() {
    let valid = true

    console.log(document.getElementById('blueprint-select')?.value)
    console.log(document.getElementById('invalid-blueprint'))
    // make sure a blueprint is selected
    if (!!document.getElementById('invalid-blueprint') && !document.getElementById('blueprint-select')?.value) {
      valid = false
      document.getElementById('invalid-blueprint').style.display = 'block';
    }

    let invalidFields = document.querySelectorAll("[data-valid=false]")
    
    invalidFields.forEach((element) => {
      valid = false
      document.getElementById(`${element.dataset['section']}-panel`)?.classList?.add('panel-danger')
    })

    if (invalidFields.length !== 0) {
      $('#invalid-form').show()
    }
    return valid;
  }

  validateForm(event) {
    if (!this.validate()) {
      event.preventDefault();
      return false
    } else {
      return true
    }
  }

  changeStylePreset(event) {
    let selected = parseInt(event.currentTarget.value,10)
    let selectedPreset = this.presetsValue.find((obj) => {
      return obj.id === selected
    })
    
    if (!selectedPreset) return false

    this.setValue('collar_style', selectedPreset.customization.collar_style)
    this.setValue('short_sleeve', selectedPreset.customization.short_sleeve)
    this.setValue('cuff_style', selectedPreset.customization.cuff_style)
    this.setValue('placket_style', selectedPreset.customization.placket_style)
    this.setValue('button_style', selectedPreset.customization.button_style)
    this.setValue('tail_style', selectedPreset.customization.tail_style)
    this.setPocketFromPreset(selectedPreset)

    if (selectedPreset.customization.cuff_lining) {
      $('#customization_hash_cuff_lining').val(selectedPreset.customization.cuff_lining)
    }
    if (selectedPreset.customization.collar_lining) {
      $('#customization_hash_collar_lining').val(selectedPreset.customization.collar_lining) 
    }
    if (selectedPreset.customization.back_collar_button) {
      $('#back-collar-button').prop('checked', true)
    }
    if (selectedPreset.customization.back_collar_button) {
      $('#back-collar-button').prop('checked', false)
    }
  }

  setValue(field, value) {
    let el = document.querySelector("input[name='customization_hash[" + field + "]'][value='" + value + "']")
    if (!el) return false

    this.setSelected(el)
  }

  setPocketFromPreset(preset) {
    let pocket_count = preset.customization.pocket_count
    let pocket_flaps = preset.customization.pocket_flaps
    let pocket_buttons = preset.customization.pocket_buttons
    let pocket_id = "pkt-" + pocket_count
    if (pocket_buttons) {
      pocket_id += "-btn" 
    }
    if (pocket_flaps) {
      pocket_id += "-flap"
    }
    this.setSelected(document.getElementById(pocket_id))
  }

  uppercaseText(event) {
    event.target.value = event.target.value.toLocaleUpperCase();
  }

  setMonogram(event) {
    if (event.target.value.length > 0) {
      this.setSelected(event.target, 'Monogram: ' + event.target.value)
    } else {
      this.resetSelected(event.target, 'Add Monogram')
    }
  }

  resetSelected(element, title) {
    let section = element.dataset['section']
    let selectedClass = element.dataset['selectedClass']
    let name = element.getAttribute('name')
    document.querySelector(`input[name='${name}']`).dataset['valid'] = false
    $(element).prop('checked', false)
    $(element).removeAttr('checked')
    $(element).parent().removeClass('active')
    this.resetHeader(section, selectedClass, title)
    $(element).change() // fire this event since it won't fire from above
  }

  resetHeader(section, selectedClass, title) {
    $(`#${section}-title .title`).html(title)
    $(`#${section}-title .title`).removeClass("selected-value")
    $(`#${section}-title #${section}-icon`).removeClass("selected-icon " + selectedClass)
    $(`#${section}-title`).find(".text-primary").removeClass("glyphicon-ok")
  }

  toggleMonogram(event) {
    let initials = document.getElementById('monogram_initials')
    if (event.currentTarget.checked) {
      initials.dataset['valid'] = false
    } else {
      this.resetHeader('monogram', 'ricon-monogram', 'Add Monogram')
      $("#monogram-panel").removeClass("panel-danger")
      initials.value = ''
      document.querySelectorAll("#monogram-fields input").forEach((element) => {
        element.dataset['valid'] = true
      })
    }
  }

  toggleShortSleeve(event) {
    let element = event.currentTarget.querySelector('input')
    element.checked = true
    this.setShortSleeve(element)
  }

  
  handleShortSleeve(event) {
    this.setShortSleeve(event.target)
  }

  setShortSleeve(element) {
    let shortSleeve = document.querySelector("input[name*='customization_hash[short_sleeve]']:checked")
    let selectedCuff = document.querySelector("input[name*='customization_hash[cuff_style]']:checked")
    let whiteCuff = document.getElementById("white_cuff")

    if (shortSleeve.value == "true") {
      $('#advanced-cuff').hide()
      if (selectedCuff) {
        this.resetSelected(selectedCuff, "Choose Short Sleeve Hem")
        this.resetSelected(whiteCuff, "Choose Short Sleeve Hem")
      } else {
        this.resetHeader("cuff_style","","Choose Short Sleeve Hem")
      }

      document.querySelectorAll("label[data-short-sleeve]").forEach((el) => {
        if ($(el).data('short-sleeve')) {
          $(el).show()
        } else {
          $(el).prop('checked', false);
          $(el).hide()
        }
      })

    } else {
      $('#advanced-cuff').show()
      if (selectedCuff) {
        this.resetSelected(selectedCuff, "Choose Cuffs")
      } else {
        this.resetHeader("cuff_style","","Choose Cuffs")
      }

      document.querySelectorAll("label[data-short-sleeve]").forEach((el) => {
        if ($(el).data('short-sleeve')) {
          $(el).prop('checked', false);
          $(el).hide()
        } else {
          $(el).show()
        }
      })
    }
  }

  modifyDependentOptions(event) {
    let changeMade = false
    let modifyFields = JSON.parse(event.target.dataset['modify'])
    let modifyAlertText = ''
    for (let [key, value] of Object.entries(modifyFields)) {
      console.log(key, value);
      modifyAlertText += $(event.target).val() + " requires " + value + ", so we've selected it for you.<br/>"
      $("input[name*='customization_hash[#{key}]'][type='hidden'").val(value)
      let radioElement = document.querySelector(`input[type='radio'][name*='customization_hash[${key}]'][value*='${value}']`)
      console.log(radioElement)
      console.log(radioElement.checked)
      if (radioElement && !radioElement.checked) {
        changeMade = true 
        this.setSelected(radioElement)
      }

      if (changeMade) {
        $('#change-notify-text').html(modifyAlertText)
        $("#change-notify").fadeIn(200)
        this.hideChangeNotification(3000)
      }
    } 
  }

  hideChangeNotification(fade = 0) {
    setTimeout(() => {
      $('#change-notify').fadeOut(200)
    }, fade)
  }

  toggleExpandIndictor(event) {
    console.log('in handler')
    $(event.target).find('.toggle-indicator').toggleClass('glyphicon-plus')
    $(event.target).find('.toggle-indicator').toggleClass('glyphicon-minus')
    let more_or_less = $(event.target).find('.toggle-caption').html()
    more_or_less =  more_or_less == "Show" ? 'Hide' : 'Show'
    $(event.target).find('.toggle-caption').html(more_or_less)
  }

  showHelp(event) {
    $('div[data-help-section]').hide()
    $("div[data-help-section*='"+$(event.currentTarget).data('help-key')+"']").show()
    offcanvas.show('#help-copy-sidebar', 'slide')
  }
}
