// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (!this.bannerClosed()) { 
      this.showBanner();
    }
  }

  showBanner() {
    setTimeout(function() {
      $('#new-user-banner').collapse('show');
    }, 5000);
  }

  bannerClosed() {
    return (sessionStorage.getItem('new_user_banner.blueprints.closed') === 'true');
  }

  closeBanner() {
    sessionStorage.setItem('new_user_banner.blueprints.closed', 'true');
    $('#new-user-banner').collapse('hide');
  }
}
