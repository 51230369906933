import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.resizeHandler = this.taxonomyPanelResize.bind(this);

    $("input[name^='search']").on('change', function(event) {
      $('input#page').val(1);
      Rails.fire(document.getElementById('sidebar_products_search'), 'submit');
    });

    if ($('#filters').length > 0) {
      window.addEventListener("resize", this.resizeHandler);
      this.taxonomyPanelResize();
    }
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  taxonomyPanelResize() {
    const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    if (viewPortWidth < 992) {
      $('#taxonomies-collapse').addClass('offcanvas-right').addClass('offcanvas-fixed');
    } else {
      $('#taxonomies-collapse').css('height', '').removeClass('offcanvas-right').removeClass('offcanvas-fixed').removeClass('hidden');
    }
  }
}
