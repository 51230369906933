// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  toggleStates() {
    let stateSelect = document.getElementById('state-select');
    let newState = document.getElementById(`country_states_${event.target.value}`)
    stateSelect.innerHTML = newState.innerHTML;
  }

  // Triggered via action declared in _order_address.html.haml
  displayAddressForm(event) {
    event.preventDefault();
    $(`#order_${event.target.dataset.orderId}_ship_address_form`).show()
    $(`#order_${event.target.dataset.orderId}_ship_address`).hide()
    $("#toggle_address_edit").hide()
  }
}
