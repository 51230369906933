import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    targetSelector: String
  }

  showOffCanvas(event) {
    offcanvas.show(this.targetSelectorValue, 'slide');
  }
}
