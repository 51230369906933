// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "couponForm", "giftCardForm" ]

  connect() {
  }

  toggle(event) {
    event.preventDefault()
    this.linksTarget.classList.add('displaynone')
    if (event.target.dataset.toggle == 'coupon-form') {
      this.hasCouponFormTarget && this.couponFormTarget.classList.remove('displaynone')
      this.giftCardFormTarget.classList.add('displaynone')
    } else if (event.target.dataset.toggle == 'gift-card-form') {
      this.giftCardFormTarget.classList.remove('displaynone')
      this.hasCouponFormTarget && this.couponFormTarget.classList.add('displaynone')
    }
  }

  cancel(event) {
    event.preventDefault()
    this.linksTarget.classList.remove('displaynone')
    this.giftCardFormTarget.classList.add('displaynone')
    this.hasCouponFormTarget && this.couponFormTarget.classList.add('displaynone')
  }
}
