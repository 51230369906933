// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $("input[name='user[profile_attributes][t_shirt_size]']").on('change', function() {
      $("#alt_t_shirt input").parent().hide();
      const size = $(this).data('size');
      const description = $(this).data('description');
      return showAlt(size, description);
    });
        
    $('input[type="radio"]').on('focus change click', function(event) {
      if ($(this).val() === 'other') {
        $(this).parents('.tab-pane').find('.ratio-text').prop('disabled', false);
        $(this).parents('.tab-pane').find('.ratio-text').prop('required', true);
        $(this).parents('.tab-pane').find('.ratio-text').parent().removeClass('displaynone');
        return $(this).parent().find('.ratio-text:first').focus();
        
      } else {
        $(this).parents('.tab-pane.active').find('.error-warning').hide();
        $(this).parents('.tab-pane').find('.ratio-text').val('');
        $(this).parents('.tab-pane').find('.ratio-text').prop('disabled', true);
        $(this).parents('.tab-pane').find('.ratio-text').parent().addClass('displaynone');
        return $(this).parents('.tab-pane').find('.ratio-text').prop('required', false);
      }
    });

    $('.ratio-text').on('focus click', function(event) {
      $(this).parent().find('input[type="radio"]').prop("checked", true);
      return $(this).parent().button('toggle');
    });

    $('#other_height_inches_without_feet').on('click', function(event) {
      event.stopPropagation();
      return $(this).focus();
    });
    
    $('#get-started-form').on('submit', function() {
      const index = $('#wizard-nav li.active').index();
      if (this.validateWizard(index) && $('#wizard-nav li.active').is(':last-child')) {
        return true;
      } else {
        return false;
      }
    });
    
    if ($('#form-error').val() === 'true') { this.goToStep(this.errorIndex()); }
    $("#loading").hide();
    $(".wizard-container").show();
  }

  checkText(element) {
    return element > this;
  }

  goBackStep(event) {
    const index = $('#wizard-nav li.active').index();
    if (this.validateWizard(index)) { this.goToStep(index-1); }
  }

  goToNextStep(event) {
    const index = $('#wizard-nav li.active').index();
    if (this.validateWizard(index)) { this.goToStep(index+1); }
  }

  showAlt(size, description) {
    $('#size-description').html(description)
    $('#alt_t_shirt .btn').parent().hide()
    $("#alt_t_shirt input").prop('checked', false)
    $("#alt_t_shirt input").parent().removeClass('active')
    $("#alt_t_shirt input[data-size='nil']").prop('checked', true)
    $("#alt_t_shirt input[data-size='nil']").parent().show()
    $("#alt_t_shirt input[data-size='nil']").parent().addClass('active')
    $("#alt_t_shirt input[data-size='nil']").parent().parent().show()
    $("#alt_t_shirt input[data-size="+(size-2)+"]").parent().show()
    $("#alt_t_shirt input[data-size="+(size-2)+"]").parent().parent().show()
    $("#alt_t_shirt input[data-size="+(size+2)+"]").parent().parent().show()
    $("#alt_t_shirt input[data-size="+(size+2)+"]").parent().show()
    $('#alt_t_shirt').collapse('show')
  }

  goToStep(index) {
    this.update_progress(index);
    this.toggleNavigation(index);
    $("#wizard-nav li:eq(" + (index) + ") a").tab('show');
    // reenable this one you update GA stuff
    // if (!user_signed_in) {
    //   ga('set', 'page', '/get-started#' + index);
    //   return ga('send', 'pageview');
    // }
  }

  errorIndex() {
    const sections = $('#error_sections').val().split(' ');
    const index_sections = new Array;
    index_sections.push($('#wizard-nav li:last-child').index());
    for (let i of Array.from(sections)) {
      const index = $('#wizard-nav a[href="#' + i + '"]').parent().index();
      if (index > -1) { index_sections.push(index); }
    }
    return Math.min.apply(null, index_sections);
  }

  toggleNavigation(index) {
    if ($("#wizard-nav li:eq(" + (index) + ")").is(':last-child') || (index === 0)) {
      $('#wizard-buttons').hide(); 
      $('.wizard-progress').hide();
      if (index !== 0) { return $('#proof').show(); }
    } else {
      $('#wizard-buttons').show();
      $('.wizard-progress').show();
      return $('#proof').hide();
    }
  }

  validateWizard(step) {
    let valid = true;
    const section = $("#wizard-nav li:eq(" + step + ") a").attr('href');
    const inputs = $(section + " input[name*='_attributes]']:checked");
    
    if (!inputs.length && ![0,3,5,9,10,17,18].includes(step)) { valid = false; } //these are the text or non-radio steps
    if ((inputs.length < 2) && (step === 4)) { valid = false; }
    if (inputs.length && (inputs.eq(0).val() === 'other')) {
      $(section + ' .ratio-text').each(function() {
        if (!!!$(this).val() || !$(this)[0].checkValidity()) {
          $(this).parents('form')[0].reportValidity();
          return valid = false;
        }
      });
    }
    
    if (step === 3) { //The age step is the only non-radio
      const input = $('#user_profile_attributes_age_years');
      if (!input[0].checkValidity()) { valid = false; }
      if (!!!input.val()) { valid = false; }
      $(input).parents('form')[0].reportValidity();
    }
      
    if (valid) {
      $(section + ' .error-warning').hide();
    } else {
      $(section + ' .error-warning').show();
    }

    return valid;
  }

  update_progress(step) {
    const text_steps = [0,5,10];
    const index_of_section = text_steps.findIndex(this.checkText, step);
    
    if (index_of_section === -1) {
     step -= 2;
    } else {
      step -= (index_of_section-1);
    }
    $('.progress-bar').attr("aria-valuenow", step);
    return $('.progress-bar').attr("style", "width: " + (((step-1)/15)*100) + "%;");
  };
}
