// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.boundIncrement = this.increment.bind(this);
    this.boundDecrement = this.decrement.bind(this); 
  }

  connect() {
    this.incrementElements = this.element.querySelectorAll(".js-increment");
    this.decrementElements = this.element.querySelectorAll(".js-decrement");

    this.incrementElements.forEach((element) => {
      element.addEventListener("click", this.boundIncrement);
    });

    this.decrementElements.forEach((element) => {
      element.addEventListener("click", this.boundDecrement);
    });
  }

  disconnect() {
    this.incrementElements.forEach((element) => {
      element.removeEventListener("click", this.boundIncrement);
    });

    this.decrementElements.forEach((element) => {
      element.removeEventListener("click", this.boundDecrement);
    });
  }

  step(mod, $button) {
    const $input = $button.parent().parent().find('input');
    const oldValue = parseFloat($input.val());
    const stepAmount = parseFloat($input.prop('step')) || 1;
    const newValue = oldValue + (mod * stepAmount);
    $input.val(newValue.toFixed(4));
    $input.change();
    this.updateDelta($input)
  };

  increment(event) {
    console.log('in increment')
    this.step(1, $(event.currentTarget));
  }

  decrement(event) {
    this.step(-1, $(event.currentTarget));
  }
 
  updateDelta($input) {
    const $addOn = $input.parent().find('.input-group-addon');
    const value = parseFloat($input.val());
    const original = parseFloat($input.data('original'));
    const delta = value - original;
    
    $addOn.text(delta.toFixed(2)).removeClass('success danger');
    
    if (delta < 0) {
      $addOn.addClass('danger');
    } else if (delta > 0) {
      $addOn.addClass('success');
    }
  };
}
